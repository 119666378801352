<template>
  <div class="w-full">
    <b-form-group
      v-slot="{ ariaDescribedby }"
      llabel-cols-sm="3"
      label-align-sm="left"
      label-size="sm"
      label="Include In Table"
    >
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="flavour-2"
      >
        <div class="grid w-full grid-cols-2">
          <b-form-checkbox value="mailed">Mailed</b-form-checkbox>
          <b-form-checkbox value="garnished">Garnished</b-form-checkbox>
          <b-form-checkbox value="disposed">Disposed</b-form-checkbox>
          <b-form-checkbox value="hearing">Hearing</b-form-checkbox>
          <b-form-checkbox value="served">Served</b-form-checkbox>
          <b-form-checkbox value="filed">Filed</b-form-checkbox>
        </div>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox, BFormGroup } from "bootstrap-vue"
export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  data() {
    return {
      selected: [
        "mailed",
        "disposed",
        "served",
        "garnished",
        "hearing",
        "filed"
      ], // Must be an array reference!
      options: [
        { text: "Mailed", value: "mailed" },
        { text: "Garnished", value: "garnished" },
        { text: "Disposed", value: "disposed" },
        { text: "Hearing", value: "hearing" },

        { text: "Served", value: "served" },

        { text: "Filed", value: "filed" }
      ]
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selected(newValue) {
      this.$emit("updateIncludes", newValue)
    }
  }
}
</script>