<template>
  <div class="flex flex-row justify-center w-ful">
    <b-card no-body class="max-w-6xl p-1 xl:min-w-6xl">
      <export-history-component
        :show-stats-modal="showStatsModal"
        :filter="filter"
        :stats="stats"
        :file-name="fileName"
        @updateFilter="updateFilter"
        @refreshExportHistoryStats="refreshExportHistoryStats"
        v-on="$listeners"
      />
    </b-card>
  </div>
</template>



<script>
import { BCard } from "bootstrap-vue"
import ExportHistoryComponent from "../components/export-history/ExportHistoryContent"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    ExportHistoryComponent,
    BCard
  },
  data() {
    return {
      showStatsModal: false,
      filter: "",
      stats: {
        total_exports: 0,
        total_records_mailed: {
          garnish: 0,
          disposed: 0,
          hearing: 0,
          served: 0,
          filed: 0,
          subtotal: 0
        },
        mailings_over_time: {
          last_30: 0,
          last_60: 0,
          last_90: 0,
          last_12_mos: 0
        }
      }
    }
  },
  computed: {
    fileName() {
      return this.$route.params.export ? this.$route.params.export : ""
    }
  },
  async created() {
    this.getExportHistoryStats()
  },
  methods: {
    toastNotify(props) {
      this.$toast({
        component: ToastificationContent,
        props: props
      })
    },
    getExportHistoryStats() {
      var l1 = this
      l1.showStatsModal = true

      l1.$AuthAxios.instance
        .get("/api-access/exports/get-export-history-stats/", {})
        .then(function(res) {
          l1.stats = res.data
          l1.showStatsModal = false
        })
        .catch((error) => {
          console.log(error)
        })
      return null
    },
    refreshExportHistoryStats(toast = false, refresh = true) {
      if (refresh) {
        this.getExportHistoryStats()
      }
      if (toast) {
        this.toastNotify(toast)
      }
    },
    updateFilter(filter) {
      this.filter = filter
    }
  }
}
</script>


