<template>
  <div>
    <b-overlay :show="busy" opacity="0.9" class="pt-1 pl-3 mr-3">
      <template #overlay spinner-variant="primary">
        <div class="d-flex align-items-center">
          <div class="w-16 ml-5 text-xl font-bold d-flex align-items-center">
            {{ hours() }}.{{ minutes() }}:{{ seconds() }}
            <!-- <b-spinner small type="grow" variant="secondary"></b-spinner>
            <b-spinner type="grow" variant="dark"></b-spinner>
            <b-spinner small type="grow" variant="secondary"></b-spinner> -->
          </div>
          <div class="flex flex-col ml-5 text-center">
            <div class="text-2xl font-bold">{{ heading }}</div>
            <div class="text-2xl font-bold">{{ busyMessage }}</div>

            <div class="mt-1 text-cente">
              <div class="italic ">
                * please note, this may take up to 10 minuites.
              </div>
              <div class="font-bold text-warning">
                Do no refresh your page.
              </div>
            </div>
          </div>

          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
      <slot></slot>
    </b-overlay>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { BOverlay } from "bootstrap-vue"

export default {
  name: "ExportOverlay",

  components: {
    BOverlay
    //BSpinner,
    // eslint-disable-next-line vue/no-unused-components
  },
  props: {
    busy: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: "Please Wait"
    },
    busyMessage: {
      type: String,
      default: "Loading"
    }
  },

  data() {
    return {
      initial: true,
      // TIMER
      isOn: false,
      new: false,
      minCount: 0,
      hrCount: 0,
      time: 0,
      isRunning: false,
      interval: null
    }
  },
  watch: {
    busy(newValue) {
      if (!newValue && this.isOn) {
        this.time = 0
        this.toggleTimer()
      } else if (newValue && !this.isOn) {
        this.toggleTimer()
      }
    }
  },
  methods: {
    seconds() {
      var zero = 0

      if (this.time > 60) {
        this.time = 0
        this.minCount += 1
      }

      return this.time.toString().length < 2
        ? zero.toString() + this.time.toString()
        : this.time.toString()
    },
    minutes() {
      var zero = 0
      if (this.minCount > 60) {
        this.minCount = 0
        this.hrCount += 1
      }
      return this.minCount.toString().length < 2
        ? zero.toString() + this.minCount.toString()
        : this.minCount.toString()
    },
    hours() {
      return this.hrCount
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval)
        this.isOn = false
      } else {
        this.isOn = true
        this.interval = setInterval(this.incrementTime, 1000)
      }
      this.isRunning = !this.isRunning
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1
    },
    toggleBusyOverlay(bool, message = "Loading") {
      if (bool) {
        this.busy = true
        this.busyMessage = message
      } else {
        this.busy = false
        this.busyMessage = "Loading"
      }
    }
  }
}
</script>

