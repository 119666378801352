<template>
  <div class="relative rounded-lg" :class="{
    'w-full': true,
    'border shadow-sm':
      rowIndex % 2 == 0 ? (!expanded ? true : false) : false,
  }">

    <div v-if="!expanded" class="absolute flex -top-5 -left-6">
      <div class="
          w-64
          px-1
          mt-1
          text-xl
          font-bold
          text-gray-200
          border
          rounded-t-lg
          shadow-md
          bg-secondary
        ">
        <div class="w-64">
          {{ tableName | capitalize }}
          <span v-if="!viewOnly" class="italic font-bold">(Preview)</span>
        </div>
      </div> 
    </div>

    <!-- STYLE ADDED FOR USING TABLES IN ROWS <div style="padding-top:10px; padding-bottom:10px">  -->
    <div>
      <div v-if="!expanded" class="flex flex-row justify-end w-full">
        <div class="flex flex-col items-center lg:flex-row">
          <div class="mr-1">
            <div class="text-xl font-bold">
              <b-input-group size="sm">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="filter" placeholder="Search" debounce="500" />
              </b-input-group>
            </div>
          </div>
          <!-- TODO: FIX BUG THAT DOES NOT ADD DATES TO THE QUERY UNLESS A DATE IS CHOSEN
                     THE DEFAULT DATE SHOLD BE SET 
          -->
          <div class="mr-1">
            <b-form-group label="Sort By" label-for="sort-by-select" label-cols-sm="3" label-align-sm="right"
              label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sort-by-select" v-model="sortBy" :options="sortOptions">
                </b-form-select>

                <b-form-select v-model="sortDesc" :disabled="!sortBy" size="sm" class="w-2 bg-gray-200">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="mr-1">
            <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="right" label-size="sm" class="mb-0">
              <!-- FIXME: PROBLEM WITH CHOOSING NUMBER PER PAGE -->
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="mr-1">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
              class="my-0"></b-pagination>
          </div>
          <div class="pr-1">
            <feather-icon v-if="!expanded" icon="Maximize2Icon" class="cursor-pointer" @click="expand" />
            <feather-icon v-else icon="Minimize2Icon" class="cursor-pointer" @click="contract" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-center justify-center mx-1 lg:flex-row">
      <div v-if="!expanded" class="
          flex flex-col
          justify-around
          w-full
          mb-1
          mr-1
          border
          rounded-lg
          lg:w-1/4
        ">
        <div class="text-center bg-gray-200">Stats</div>

        <div class="flex flex-col px-1 pt-1">
          <div class="flex flex-row justify-between font-bold">
            <div class="flex flex-row items-center">Total Cases:</div>
            <div>{{ responseData.stats.total_cases }}</div>
          </div>
          <!-- DUPLICATES -->
          <div class="flex flex-row justify-between" :class="activeTest(responseData.exportFilters.duplicates.value, 'any')
    ">
            <div class="flex flex-row items-center">
              <feather-icon icon="MailIcon" style="margin-right: 5px" />Previously Mailed:
            </div>
            <div>
              {{ responseData.stats.previous_mailing }}
            </div>
          </div>
          <div class="flex flex-row justify-between" :class="activeTest(responseData.exportFilters.addresses.value, 'combined')
    ">
            <div class="flex flex-row items-center">
              <feather-icon icon="UsersIcon" style="margin-right: 5px" />Shared
              Addresses:
            </div>
            <div>{{ responseData.stats.shared_addresses }}</div>
          </div>
          <div class="flex flex-row justify-between"
            :class="activeTest(responseData.exportFilters.tracked.value, false)">
            <div class="flex flex-row items-center">
              <feather-icon icon="MapPinIcon" style="margin-right: 5px" />Tracked Cases:
            </div>
            <div>{{ responseData.stats.total_tracked }}</div>
          </div>
        </div>
      </div>
      <!-- TODO: UPDATE TABLE TO SHOW 'NO RESULTS' WHEN TABLE IS EMPTY AND KEEP SAME HEIGHT -->
      <b-overlay :show="busy" :class="{ 'w-3/4': !expanded ? true : false, 'rounded-lg': true }">
        <!--  <template #overlay>
          <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label"
            class="w-full text-center">
            <p v-if="removeAll">
              <strong id="form-confirm-label w-full">Are you sure you want to remove
                <span class="underline">all</span> cases in current
                query??</strong>
            </p>
            <p v-else>
              <strong id="form-confirm-label w-full">Are you sure you want to remove {{ tempCase }} from this
                export??</strong>
            </p>
            <div class="">
              <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                Cancel
              </b-button>
              <b-button v-if="removeAll" variant="outline-success" @click="removePartiesAll">Yes</b-button>
              <b-button v-else variant="outline-success" @click="removeParty">Yes</b-button>
            </div>
          </div>
        </template> -->
        <div v-if="expanded" class="flex flex-row items-end justify-between w-full mt-1 mb-1">
          <div class="
              flex flex-col
              justify-around
              w-full
              mt-2
              mb-1
              mr-1
              border
              rounded-lg
              lg:w-1/4
            ">
            <div class="text-center bg-gray-200">Stats</div>

            <div class="flex flex-col px-1 pt-1">
              <div class="flex flex-row justify-between font-bold">
                <div class="flex flex-row items-center">Total Cases:</div>
                <div>{{ responseData.stats.total_cases }}</div>
              </div>
              <!-- DUPLICATES -->
              <div class="flex flex-row justify-between" :class="activeTest(responseData.exportFilters.duplicates.value, 'any')
    ">
                <div class="flex flex-row items-center">
                  <feather-icon icon="MailIcon" style="margin-right: 5px" />Previously Mailed:
                </div>
                <div>
                  {{ responseData.stats.previous_mailing }}
                </div>
              </div>
              <div class="flex flex-row justify-between" :class="activeTest(
    responseData.exportFilters.addresses.value,
    'combined'
  )
    ">
                <div class="flex flex-row items-center">
                  <feather-icon icon="UsersIcon" style="margin-right: 5px" />Shared Addresses:
                </div>
                <div>{{ responseData.stats.shared_addresses }}</div>
              </div>
              <div class="flex flex-row justify-between" :class="activeTest(responseData.exportFilters.tracked.value, false)
    ">
                <div class="flex flex-row items-center">
                  <feather-icon icon="MapPinIcon" style="margin-right: 5px" />Tracked Cases:
                </div>
                <div>{{ responseData.stats.total_tracked }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center lg:flex-row">
            <div class="mr-1">
              <div class="text-xl font-bold">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input v-model="filter" placeholder="Search" debounce="500" />
                </b-input-group>
              </div>
            </div>
            <!-- TODO: FIX BUG THAT DOES NOT ADD DATES TO THE QUERY UNLESS A DATE IS CHOSEN
                     THE DEFAULT DATE SHOLD BE SET 
          -->
            <div class="mr-1">
              <b-form-group label="Sort By" label-for="sort-by-select" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select id="sort-by-select" v-model="sortBy" :options="sortOptions">
                  </b-form-select>

                  <b-form-select v-model="sortDesc" :disabled="!sortBy" size="sm" class="w-2 bg-gray-200">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="mr-1">
              <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
                label-cols-lg="3" label-align-sm="right" label-size="sm" class="mb-0">
                <!-- FIXME: PROBLEM WITH CHOOSING NUMBER PER PAGE -->
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </div>
            <div class="mr-1">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                class="my-0"></b-pagination>
            </div>

          </div>

        </div>

        <b-table v-if="!expanded" ref="table" class="relative border rounded-lg" :items="backendCall" :filter="filter"
          no-local-sorting fixed show-empty :sticky-header="boundTableHeight()" :fields="fields" :per-page="perPage"
          :busy.sync="isBusy" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          @filtered="onFiltered" @sort-changed="onSortChanged">
          <!-- date -->
          <template #head(remove)="/* eslint-disable-line */ data" class="w-full h-full bg-gray-500">
            Top
          </template>
          <template #cell(remove)="data">
            <div>
              <div class="relative">
                <b-form-group>
                  <b-form-checkbox v-model="toBeDeleted[data.item.unique_id]" :name="`buttons-${data.index}`" buttons
                    button-variant="primary" />
                </b-form-group>
              </div>
            </div>
          </template>
          <template #cell(stats)="data">
            <div class="d-flex align-items-center" style="min-width: 70px; max-width: 70px">
              <div class="flex flex-row items-center justify-between w-full">
                {{ data.uid }}

                <feather-icon :class="activeTest(data.item.previous_export, false)" icon="MailIcon" />
                <feather-icon :class="activeTest(data.item.defendant.multiple, false)" icon="UsersIcon" />
                <feather-icon :class="activeTest(data.item.is_tracked, false)" icon="MapPinIcon" />
              </div>
            </div>
          </template>
          <template #cell(filed)="data">

            <div class="d-flex align-items-center text-truncate" style="max-width: 80px">
              <span>{{ data.item.file_date }} </span>
            </div>
          </template>
          <template #cell(case)="data">
            <div class="d-flex align-items-center text-truncate">
              <span>{{ data.item.case_number }} </span>
            </div>
          </template>

          <template v-if="sortBy === 'plaintiff'" #cell(plaintiff)="data">
            <div class="d-flex align-items-center truncate">
              <span>{{ truncateText(data.item.plaintiff) }} </span>
            </div>
          </template>
          <template v-else #cell(defendant)="data">
            <div class="d-flex align-items-center" style="max-width: 290px" :set="(edit = false)">
              <b-form-input v-if="edit" id="input-small" v-model="removedNames[data.item.defendant.defendant_name]"
                size="sm" :placeholder="data.item.defendant.defendant_name" @blur="
                  nameChange(data.item);
                  edit = false;
                "></b-form-input>
              <div v-else class="cursor-pointer" @click="edit = true">
                <feather-icon icon="PencilIcon" />
                <span>{{ data.item.defendant.defendant_name }} </span>
              </div>
            </div>
          </template>

          <template #cell(amount)="data">
            <div class="d-flex align-items-center" style="max-width: 30px">
              <span>{{ data.item.amount | currency }} </span>
            </div>
          </template>

          <template #table-busy>
            <div class="my-2 text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <b-table v-else ref="table" class="relative border rounded-lg" :items="backendCall" :filter="filter"
          no-local-sorting show-empty :fields="fields" :per-page="perPage" :busy.sync="isBusy"
          :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @filtered="onFiltered"
          @sort-changed="onSortChanged">
          <!-- date -->
          <template v-if="!viewOnly" #head(remove)="/* eslint-disable-line */ data" @click.stop>
            <div @click.stop>
              <div style="white-space: nowrap;">
                do not mail
              </div>

              <div :class="`
      ${toBeDeletedArray.length > 0 ? '' : 'invisible'}
      flex flex-row
      items-center
      justify-center
      px-1
      mt-1
      bg-warning
      rounded-lg
      shadow-sm
      cursor-pointer
    `" @click.stop="areYouSure(data)">

                <feather-icon class="" icon="Trash2Icon" />
              </div>
            </div>
          </template>

          <template #cell(remove)="data">
            <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
              <b-form-checkbox v-model="toBeDeleted[data.item.unique_id]" :name="`buttons-${data.index}`" buttons
                button-variant="primary" />
            </div>
          </template>
          <template #cell(details)="data">

            <div class="">
              <b-button class="" variant="secondary" size="sm" @click="onRowSelected(data)">Details</b-button>
            </div>
          </template>
          <template #cell(filed)="data">
            <div class="d-flex align-items-center text-truncate" style="max-width: 80px">
              <span>{{ data.item.file_date }} </span>
            </div>
          </template>
          <template #cell(county)="data">
            <div class="d-flex align-items-center text-truncate" style="max-width: 80px">
              <span>{{ data.item.county }} </span>
            </div>
          </template>
          <template v-if="sortBy === 'plaintiff'" #cell(plaintiff)="data">
            <div class="d-flex align-items-center truncate">
              <span>{{ truncateText(data.item.plaintiff) }} </span>
            </div>
          </template>
          <template v-else #cell(defendant)="data" style="min-width: 300px">
            <div class="d-flex align-items-center" :set="
                (removedNames[data.item.defendant.defendant_name] =
                  defendantName(data.item, data.index))
              ">

              <b-form-input id="input-small" v-model="removedNames[data.item.defendant.defendant_name]" size="sm"
                @blur="nameChange(data.item, data.index)"></b-form-input>
            </div>
          </template>
          <template #cell(amount)="data">
            <div class="d-flex align-items-center" style="max-width: 30px">
              <span>{{ data.item.amount | currency }} </span>
            </div>
          </template>

          <template #table-busy>
            <div class="my-2 text-center text-danger">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      <!--  <pre>{{ toBeDeleted }} {{ toBeDeletedArray }}}</pre> -->
      </b-overlay>
    </div>
    <div v-if="showModal">
      <b-modal id="modal-center" v-model="showModal" size="xl" scrollable header-class="bg-white" hide-footer>
        <!-- <p class="my-4">{{ modalValue.item }}</p> -->
        <total-case-view :casedata="caseData" v-on="$listeners">
        </total-case-view>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import TotalCaseView from "@/views/components/total-case-view/TotalCaseView.vue";
//import { integer } from "vee-validate/dist/rules"

export default {
  components: {
    TotalCaseView,
    BOverlay,
    BButton,
    BFormGroup,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BFormInput,
    BSpinner,
    //BImg
  },

  props: {


    filename: {
      type: String,
      default: "None",
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: String,
      default: "120px",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    packedFilters: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    responseData: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showEdit: {},
      removedNames: {},

      viewOnlyRefresh: false,
      viewOnlyInitialLoad: false,
      removeAll: false,
      refresh: false,
      caseData: { is_tracked: "NONE" },
      dataIndex: 0,
      showModal: false,
      busy: false,
      tempCase: "",
      removed: [],
      filter: "",
      isBusy: false,
      sortDesc: true,
      varInitialLoad: true,
      totalRows: 1,
      exportFilters: {},
      sortDescOptions: [
        { value: "asc", text: "ASC" },
        { value: "desc", text: "DESC" },
      ],
      pageOptions: [5, 8, 10, 15, { value: 100, text: "Show All" }],
      showAll: 0,
      sortBy: "defendant",

      perPage: 100, //this.viewOnly ? 7 : 10,
      currentPage: 1,
      toBeDeleted: {},
      toBeDeletedArray: [],
      toBeDeletedIndex: [],
      dontSort: false,
    };
  },

  computed: {
    fields() {
      if (this.claims) {
        return [
          { key: "remove", label: "", sortable: false },
          { key: "details", label: "Details", sortable: false },
          //{ key: "stats", label: "Stats", sortable: false },
          { key: "defendant", label: "Creditor", sortable: true },
          { key: "total_address", label: "Address", sortable: true },
          { key: "filed", label: "Filed", sortable: true },
        ];
      }
      if (this.viewOnly) {
        return [
          { key: "remove", label: "details", sortable: false },
          { key: "details", label: "Details", sortable: false },
          { key: "stats", label: "Stats", sortable: false },
          { key: "filed", label: "Filed", sortable: true },
          { key: "defendant", label: "Defendant", sortable: true },
          { key: this.sortBy === 'plaintiff' ? 'plaintiff' : 'defendant', label: this.sortBy === 'plaintiff' ? 'Plaintiff' : "Defendant", sortable: true },
          { key: "case", label: "Case", sortable: true },
          { key: "amount", label: "Amount", sortable: true },
        ];
      }
      return [
        { key: "remove", label: "", sortable: false },
        { key: "details", label: "Details", sortable: false },
        //{ key: "stats", label: "Stats", sortable: false },

        { key: this.sortBy === 'plaintiff' ? 'plaintiff' : 'defendant', label: this.sortBy === 'plaintiff' ? 'Plaintiff' : "Defendant", sortable: true },
        { key: "city", label: "City", sortable: true },
        { key: "state", label: "State", sortable: true },
        { key: "zip_code", label: "Zip", sortable: true },
        { key: "filed", label: "Filed", sortable: true },
        //{ key: "case", label: "Case", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
      ];
    },
    sortOptions() {
      // Create an options list from our fields
      var fields = this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
      fields.push({ text: "Mailed", value: "mailed" });
      fields.push({ text: "Tracked", value: "tracked" });
      fields.push({ text: "Shared Addresses", value: "addresses" });

      if (this.sortBy === 'plaintiff') {
        fields.push({ text: "Defendant", value: "defendant" });
      } else {
        fields.push({ text: "Plaintiff", value: "plaintiff" });
      }

      return fields;
    },

    tableData() {
      return this.responseData.results;
    },
    count() {

      return this.responseData.count;
    },
    previous() {
      return this.responseData.previous;
    },
    next() {
      return this.responseData.next;
    },

  },
  watch: {
    toBeDeleted: {
      handler(newValue) {
        this.toBeDeletedArray = [];
        this.toBeDeletedIndex = [];
        Object.entries(newValue).forEach(([key, value], index) => {
          if (value) {
            var delData = {
              rowIndex: index,
              case: key,
              tableName: this.tableName,
            }
            this.toBeDeletedArray.push(delData);
            this.toBeDeletedIndex.push(key);
          }
        });
      },
      deep: true
    },
    responseData: {
      async handler(newValue) {
        this.varInitialLoad = true;
        this.totalRows = newValue.count;
        this.viewOnlyRefresh = true;


        this.isBusy = false;
      },
      deep: true,
    },
    packedFilters: {
      handler(newValue) {
        this.exportFilters = newValue;
      },
      deep: true,
    },
  },
  created() {


    this.responseData.results.forEach(element => {
      this.$set(this.toBeDeleted, element.unique_id, false); // Ensure reactivity
    });


    this.exportFilters = this.packedFilters;
    //console.log(this.responseData)
    this.totalRows = this.responseData.count;
  },
  methods: {


    truncateText(text) {
      if (typeof text === 'string') {
        return text.length > 30 ? text.slice(0, 30) + "..." : text
      }
      return text
    },
    defendantName(item, index) {
      let defendant;

      if (
        this.removedNames[item.unique_id] &&
        item.defendant.defendant_name != this.removedNames[item.unique_id]
      ) {
        defendant = this.removedNames[item.unique_id];
        this.$emit("updateName", {
          rowIndex: index,
          tableName: this.tableName,
          newName: this.removedNames[item.unique_id],
        });
      }
      defendant = item.defendant.defendant_name;
      return defendant;
    },
    toggleEdit(item) {
      this.showEdit[item] = true;
      if (typeof this.$refs.table !== 'undefined') {
        this.$refs.table.refresh();
      }
    },
    nameChange(item, index) {
      const newName = this.removedNames[item.defendant.defendant_name];

      delete this.removedNames[item.defendant.defendant_name];

      this.removedNames[item.unique_id] = newName;

      this.$emit("updateName", {
        rowIndex: index,
        tableName: this.tableName,
        newName: newName,
      });

      this.$emit("setNameUpdates", {
        unique_id: item.unique_id,
        newName: newName,
      });
    },

    boundTableHeight() {
      if (!this.embeded) {
        return "120px";
      }
      return "600px";
    },
    contract() {
      this.$emit("contract");
    },
    expand() {
      this.$emit("expand", {
        four: this.tableName,
      });
    },

    onRowSelected(selData) {
      var data = selData.item;

      var l1 = this;
      this.isBusy = true;
      l1.$AuthAxios.instance
        .get("/api-access/search/case-search/", {
          params: {
            case_number: data.unique_id,
            single: true
          },
        })
        .then((res) => {

          var data = res.data;

          data["index"] = 0;
          data["table_model"] = { key: "case_of_party", label: "Details" };
          data["index"] = selData.index;
          data["tableName"] = l1.tableName;
          l1.caseData = data;

          l1.showModal = true;
          l1.$refs.table.clearSelected();
          l1.isBusy = false;
        })
        .catch((error) => {
          l1.isBusy = false;

          console.log("verify-user endpoint ERROR ", error);

          throw new Error({ error: error });
        });
    },
    areYouSure(data, all) {
      console.log(all)
      this.busy = true;
      //if (!all) {
      //  this.dataIndex = data.index;
      //  this.tempCase = data.item.unique_id;
      //  console.log(this.tempCase)
      //}
      this.removeParty();
    },
    onCancel() {
      this.busy = false;
    },
    removePartiesAll() {
      this.$emit("removeRowsAll", {
        tableName: this.tableName,
      });
      this.tempCase = "";

      this.busy = false;
    },

    async updateAfterRemoved(){
   
      this.tempCase = "";
      await this.$refs.table.refresh()

      
      
      let newDeleted = {};
      await this.responseData.results.forEach(element => {
        newDeleted[element.unique_id] = false
      });
      this.toBeDeleted = newDeleted
      this.toBeDeletedArray = [];
      this.toBeDeletedIndex = [];


      this.busy = false;
    
    },
    async removeParty() {
    
      await this.$emit("removeRow", {
        uidArray: this.toBeDeletedIndex,
        rowData: this.toBeDeletedArray,
      });
     

    },
    backendCall(ctx, callback) {
      const l1 = this;
      if (l1.dontSort) {
        l1.dontSort = false
        console.log("CALLBACK ON SORT", l1.responseData)
        callback(l1.responseData.results)
        return null;
      }

      var x;

      if (l1.viewOnly) {
        if (l1.viewOnlyRefresh) {
          l1.viewOnlyRefresh = false;
          callback(l1.tableData);
          if (typeof l1.$refs.table !== 'undefined') {
            l1.$refs.table.refresh();
          }
          return null;
        }

        if (l1.varInitialLoad) {
          l1.varInitialLoad = false;
          callback(l1.tableData);
          if (typeof l1.$refs.table !== 'undefined') {
            l1.$refs.table.refresh();
          }

          return null;
        }

        l1.exportFilters["mailingType"] = {};
        l1.exportFilters["stage"] = { key: "", value: "" };

        x = (ctx.currentPage - 1) * this.perPage;

        if (x < 0) {
          x = x - 1;
        }

        if (this.perPage === 100) {
          ctx["limit"] = l1.responseData.stats.total_cases;
          ctx["offset"] = 0;
        } else {
          ctx["limit"] = ctx.perPage;
          ctx["offset"] = x;
        }

        if (!l1.refresh) {
          l1.exportFilters.mailingType[l1.tableName] = { ctx: ctx };
          l1.exportFilters.stage.key = 2;
          l1.exportFilters.stage.value = l1.tableName;
        }

        l1.$emit(
          "viewExports",
          { key: l1.tableName },
          { item: { filename: l1.filename } },
          l1.exportFilters
        );
        callback(l1.tableData);
        if (typeof l1.$refs.table !== 'undefined') {
          l1.$refs.table.refresh();
        }
        return null;
      } else if (l1.varInitialLoad) {
        l1.varInitialLoad = false;
        callback(l1.tableData);
        if (typeof l1.$refs.table !== 'undefined') {
          l1.$refs.table.refresh();
        }

        return null;
      } else {
        x = (ctx.currentPage - 1) * this.perPage;

        if (x < 0) {
          x = x - 1;
        }

        if (this.perPage === 100) {
          ctx["limit"] = l1.responseData.stats.total_cases;
          ctx["offset"] = 0;
        } else {
          ctx["limit"] = ctx.perPage;
          ctx["offset"] = x;
        }

        if (!l1.refresh) {
          l1.exportFilters.mailingType[l1.tableName].ctx = ctx;
          l1.exportFilters.stage.key = 2;
          l1.exportFilters.stage.value = l1.tableName;
        }

        l1.$AuthAxios.instance
          .get("/api-access/exports/filter-initial-list/", {
            params: {
              filters: l1.exportFilters,
            },
          })
          .then(function (res) {
            if (!l1.refresh) {
              l1.exportFilters.mailingType[l1.tableName].ctx = ctx;
              l1.exportFilters.mailingType[l1.tableName].ctx.currentPage = 1;
              l1.exportFilters.mailingType[l1.tableName].ctx.limit = 10;
              l1.exportFilters.mailingType[l1.tableName].ctx.offset = 0;
            }

            l1.refresh = false;

            l1.$emit("UpdateInitialQueryset", {
              responseData: res.data,
              packedFilters: l1.exportFilters,
              key: l1.tableName,
              countyCounts: res.data[0].county_counts,
            });

            callback(l1.tableData);
            return null;
          })
          .catch((error) => {
            l1.isBusy = false;
            console.log(error);
            return null;
          });

        return null;
      }
    },
    onFiltered(filteredItems) {
  
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSortChanged(ctx) {

      if (!ctx.sortBy) {
        this.dontSort = true;
        this.isBusy = false;
        return;
      }
      // Otherwise, update the sort state
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
    activeTest(obj, is) {
      if (obj !== is) {
        return {
          "text-success": true,
        };
      }
      return {
        "text-gray-300": true,
      };
    },

    formatDateTime(sDate, FormatType) {
      var lDate = new Date(sDate);

      var month = new Array(12);
      month[0] = "January";
      month[1] = "February";
      month[2] = "March";
      month[3] = "April";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "August";
      month[8] = "September";
      month[9] = "October";
      month[10] = "November";
      month[11] = "December";

      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      var hh =
        lDate.getHours() < 10 ? "0" + lDate.getHours() : lDate.getHours();
      var mi =
        lDate.getMinutes() < 10 ? "0" + lDate.getMinutes() : lDate.getMinutes();
      var ss =
        lDate.getSeconds() < 10 ? "0" + lDate.getSeconds() : lDate.getSeconds();

      var d = lDate.getDate();
      var dd = d < 10 ? "0" + d : d;
      var yyyy = lDate.getFullYear();
      var mon = eval(lDate.getMonth() + 1);
      var mm = mon < 10 ? "0" + mon : mon;
      var monthName = month[lDate.getMonth()];
      var weekdayName = weekday[lDate.getDay()];

      if (FormatType == 1) {
        return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi;
      } else if (FormatType == 2) {
        return weekdayName + ", " + monthName + " " + dd + ", " + yyyy;
      } else if (FormatType == 3) {
        return mm + "/" + dd + "/" + yyyy;
      } else if (FormatType == 4) {
        var dd1 = lDate.getDate();
        return dd1 + "-" + monthName + "-" + yyyy;
      } else if (FormatType == 5) {
        return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi + ":" + ss;
      } else if (FormatType == 6) {
        return mon + "/" + d + "/" + yyyy + " " + hh + ":" + mi + ":" + ss;
      } else if (FormatType == 7) {
        return (
          dd +
          "-" +
          monthName.substring(0, 3) +
          "-" +
          yyyy +
          " " +
          hh +
          ":" +
          mi +
          ":" +
          ss
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
